import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styles from "styles/commonModules/ResourcesList.module.scss";
import { getDoctorResources } from "services/api/ApiService";
import { useTranslation } from "react-i18next";
import Loader from "componentsShared/Loader/Loader";
import { ResourceLink } from "сomponents/ResourceLink/ResourceLink";
import { Checkbox, Collapse, Layout } from "element-react";

const sortByOrder = (a, b) => {
    return a.order - b.order;
};

const Section = ({ sectionId, subGroups, allowSelection, onResourceSelect, selectedResources, addTouchedId }) => {
    if (subGroups.length === 0) {
        return null;
    }

    return (
        <div className={styles["resources-section-collapsible"]}>

            {subGroups.sort(sortByOrder).map(({ id, name, children, url, type }) => {
                if (type === "RESOURCE") {
                    return (
                        <ul key={id} className={styles.resourcesList}>
                            <li key={id} className={styles.resourcesListItem}>
                                {
                                    (
                                        allowSelection && 
                                        <Checkbox
                                            key={name+id}
                                            label={id}
                                            value={{url, name}}
                                            onChange={(e) => {onResourceSelect(e, {id, url, name}); addTouchedId(sectionId);}}
                                            checked={(selectedResources.find(el=>el.id==id)) ? true : false}><ResourceLink url={url} name={name} /></Checkbox>
                                    ) ||
                                    <ResourceLink url={url} name={name} />
                                }
                            </li>
                        </ul>
                    );
                }

                return (
                    <ul key={id} className={styles.resourcesList}>
                        <h4>{name}</h4>
                        {children.sort(sortByOrder).map(({ id, name, url }) => {
                            return (
                                <li key={id} className={styles.resourcesListItem}>
                                {
                                    (
                                        allowSelection && 
                                        <Checkbox
                                            key={name+id}
                                            label={id}
                                            value={{url, name}}
                                            onChange={(e) => {onResourceSelect(e, {id, url, name}); addTouchedId(sectionId);}}
                                            checked={(selectedResources.find(el=>el.id==id)) ? true : false}><ResourceLink url={url} name={name} /></Checkbox>
                                    ) ||
                                    <ResourceLink url={url} name={name} />
                                }
                                </li>
                            );
                        })}
                    </ul>
                );
            })}
        </div>
    );
};

DoctorResourcesList.propTypes = {
    allowSelection: PropTypes.bool,
    onResourceSelect: PropTypes.func,
    selectedResources: PropTypes.array,
};

function DoctorResourcesList({allowSelection, onResourceSelect, selectedResources}) {
    const [isLoading, setLoading] = useState(true);
    const [resources, setResources] = useState([]);
    const [touchedIdsFirst, setTouchedIdsFirst] = useState([]);
    const [touchedIdsSecond, setTouchedIdsSecond] = useState([]);
    const { t } = useTranslation();

    const addTouchedIdFirst = (id) => {
        setTouchedIdsFirst([...touchedIdsFirst, id]);
    };

    const addTouchedIdSecond = (id) => {
        setTouchedIdsSecond([...touchedIdsSecond, id]);
    };

    useEffect(() => {
        setLoading(true);
        getDoctorResources()
            .then((response) => {
                setResources(response);
        
                // Open the first section by default
                if (response.length > 0) {
                    addTouchedIdFirst(response[0].id);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const sortedResources = resources.sort(sortByOrder);
    const middleIndex = Math.ceil(sortedResources.length / 2); // Get the middle index (round up for odd-length arrays)
    const firstHalf = sortedResources.slice(0, middleIndex); // Slice from start to middle
    const secondHalf = sortedResources.slice(middleIndex); // Slice from middle to end

    return (
        <div className={styles["resources-section-wrapper"]}>
            {isLoading && (
                <div className={styles["resources-section-loader"]}>
                    <Loader isVisible />
                </div>
            )}

            {resources.length === 0 && !isLoading && (
                <div className={styles["resources-no-data"]}>
                    {t("Resources:DoctorResourcesList.noData")}
                </div>
            )}

            <div>
                <Layout.Row gutter="20">
                {
                    firstHalf &&
                    <Layout.Col span="10" offset="2">
                        <Collapse value={touchedIdsFirst}>
                        {firstHalf.map(({ id, name, children }) => (
                            <Collapse.Item title={name} key={id} name={id}>
                                <Section 
                                    sectionId={id}
                                    subGroups={children}
                                    allowSelection={allowSelection}
                                    onResourceSelect={onResourceSelect}
                                    selectedResources={selectedResources ? [...selectedResources] : []}
                                    addTouchedId={addTouchedIdFirst}
                                />
                            </Collapse.Item>
                        ))}
                        </Collapse>
                    </Layout.Col>
                }
                
                {
                    secondHalf &&
                    <Layout.Col span="10">
                        <Collapse value={touchedIdsSecond}>
                        {secondHalf.map(({ id, name, children }) => (
                            <Collapse.Item title={name} key={id} name={id}>
                                <Section
                                sectionId={id}
                                subGroups={children}
                                allowSelection={allowSelection}
                                onResourceSelect={onResourceSelect}
                                addTouchedId={addTouchedIdSecond}
                                selectedResources={selectedResources ? [...selectedResources] : []}
                            />
                            </Collapse.Item>
                        ))}
                        </Collapse>
                    </Layout.Col>
                }
                </Layout.Row>
            </div>
        </div>
    );
}

Section.propTypes = {
    allowSelection: PropTypes.bool,
    subGroups: PropTypes.array.isRequired,
    sectionId: PropTypes.string.isRequired,
    onResourceSelect: PropTypes.func,
    selectedResources: PropTypes.array,
    addTouchedId: PropTypes.func,
};

export default DoctorResourcesList;
