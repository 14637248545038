import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Dialog } from 'element-react';

import DoctorResourcesList from 'сomponents/DoctorResourcesList/DoctorResourcesList';
import customComponentsStyles from 'styles/custom-components.module.scss';
import styles from './AdditionalResourcesDialog.module.scss';
import { CARE_PATHWAY_AGREED_FIELD_ID } from '../SurveysResults';

AdditionalResourcesDialog.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setIsVisible: PropTypes.func.isRequired,
  setConclusion: PropTypes.func.isRequired,
  conclusion: PropTypes.object.isRequired
};

function AdditionalResourcesDialog({isVisible, setIsVisible, setConclusion, conclusion}) {
  const { t } = useTranslation();
  const [selectedResources, setSelectedResources] = useState([]);
  
  const onResourceSelect = (isSelected, resource) => {

    const existingResource = selectedResources.find(el=>el.id == resource.id);
    const existingText = conclusion[CARE_PATHWAY_AGREED_FIELD_ID]?.text || "";
    const { name, url } = resource;

    if (isSelected) {

      if (!existingResource){

        setSelectedResources([...selectedResources, resource]);
      }

      const textToAdd = (existingText.length ? existingText +'\n\n' : '')
                      + `${name}\n`
                      + '-'.repeat(name.length)
                      + `\n${url}`;

        setConclusion({
            ...conclusion,
            [CARE_PATHWAY_AGREED_FIELD_ID]: {
                id: CARE_PATHWAY_AGREED_FIELD_ID,
                text: textToAdd,
            },
        });
    }
    else if (!isSelected) {

      if (existingResource){
        selectedResources.splice(selectedResources.indexOf(existingResource), 1);
        setSelectedResources([...selectedResources]);
      }
      const textToRemove = `${name}\n`
                      + '-'.repeat(name.length)
                      + `\n${url}`;

      setConclusion({
          ...conclusion,
          [CARE_PATHWAY_AGREED_FIELD_ID]: {
              id: CARE_PATHWAY_AGREED_FIELD_ID,
              text: conclusion[CARE_PATHWAY_AGREED_FIELD_ID]?.text
                  ?.replace(`\n\n${textToRemove}`, "")
                  .replace(textToRemove, ""),
          },
      });
    }
  };

  const onClose = () => {
    setIsVisible(false);
  };

  return (
    <Dialog
      title={
        <span className={styles.dialogHeading}>
          {t('Resources:AdditionalResourcesDialog.header.heading')}
        </span>
      }
      size="large"
      top="5%"
      visible={ isVisible }
      onCancel={onClose}
      lockScroll={ false }
      closeOnClickModal={false}
    >
      <Dialog.Body>
        <div className={styles.subheading}>
          {t('Resources:AdditionalResourcesDialog.header.subHeading')}
        </div>
        <DoctorResourcesList allowSelection={true} onResourceSelect={onResourceSelect} selectedResources={[...selectedResources]} />
      </Dialog.Body>
      <Dialog.Footer className="dialog-footer">
        <Button
          onClick={onClose}
          className={customComponentsStyles.buttonCustom}
        >
          {t('Resources:AdditionalResourcesDialog.button.close')}
        </Button>
      </Dialog.Footer>
    </Dialog>
  );
}

export default AdditionalResourcesDialog;
