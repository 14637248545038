import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContentWithHeaderLayout from 'layouts/pageContentLayouts/ContentWithHeaderLayout/ContentWithHeaderLayout';
import DoctorResourcesList from 'сomponents/DoctorResourcesList/DoctorResourcesList';
import styles from './DoctorResourcesView.module.scss';

function DoctorResourcesView() {
  const { t } = useTranslation();

    const [selectedResources, setSelectedResources] = useState([]);
  
  const onResourceSelect = (isSelected, resource) => {
    
    let tempResources = selectedResources;
    const existingResource = tempResources.find(el=>el.id == resource.id);

    if (isSelected) {

      if (!existingResource){

        tempResources = [...tempResources, resource];
        setSelectedResources(tempResources);
      }
    }
    else if (!isSelected) {

      if (existingResource){
        tempResources.splice(tempResources.indexOf(existingResource), 1);
        setSelectedResources([...tempResources]);
      }
    }

    const textToCopy = tempResources.reduce((acc, curr) => {

        return acc += (acc.length ? '\n\n' : '')
                    + `${curr.name}\n`
                    + '-'.repeat(curr.name.length)
                    + `\n${curr.url}`;
    }, '');

    navigator.clipboard.writeText(textToCopy)
            .catch((err) => console.error('Failed to copy:', err));
  };

  return (
    <ContentWithHeaderLayout
      padding
      bg
      headerTitle={t('Resources:DoctorResourcesView.header.heading')}
    >
      <div className={styles.subheading}>
        {t('Resources:DoctorResourcesView.header.subHeading')}
      </div>
      <DoctorResourcesList allowSelection={true} onResourceSelect={onResourceSelect} selectedResources={[...selectedResources]} />
    </ContentWithHeaderLayout>
  );
}

export default DoctorResourcesView;
