import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { Checkbox, Collapse } from "element-react";
import { Link } from "react-router-dom";
import { USER_ROLES } from "constants/userRoles";

import customComponentsStyles from "styles/custom-components.module.scss";
import styles from "./Recommendations.module.scss";
import { ResourceLink } from "сomponents/ResourceLink/ResourceLink";

Recommendations.propTypes = {
    isSurveyStatusCompleted: PropTypes.bool.isRequired,
    recommendations: PropTypes.array.isRequired,
    setRecommendations: PropTypes.func.isRequired,
    addResourceLink: PropTypes.func.isRequired,
    removeResourceLink: PropTypes.func.isRequired,
};

function Recommendations({
    isSurveyStatusCompleted,
    recommendations,
    setRecommendations,
    addResourceLink,
    removeResourceLink,
}) {
    const [touchedIds, setTouchedIds] = useState([]);

    const surveyInstanceRecommendationsData = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceRecommendations.data", {})
    );

    const surveyInstanceRecommendationsDataSequence = useSelector((state) =>
        get(state.surveys.surveyInstance, "instanceRecommendations.dataSequence", [])
    );

    const accountType = useSelector((state) => state.auth.accountType);

    const renderRecommendationsList = () => {
        return (
            <Fragment>
                {surveyInstanceRecommendationsDataSequence.map((key) => {
                    const possibleAnswers = get(
                        surveyInstanceRecommendationsData,
                        `${[key]}.possibleAnswers`,
                        []
                    );
                    const question = get(
                        surveyInstanceRecommendationsData,
                        `${[key]}.question`,
                        ""
                    );

                    return (
                        <div key={key} className={styles.patientView}>
                            <h4>{question}</h4>
                            <ul className={styles.list}>
                                {possibleAnswers.map((item) => {
                                    return (
                                        <li className={styles.listItem} key={item.id}>
                                            {item.answer}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    );
                })}
            </Fragment>
        );
    };

    const renderRecommendationsListCollapsed = () => {
        return (
            <div className={customComponentsStyles.collapseCustomWrapper}>
                <Collapse value={touchedIds}>
                    {surveyInstanceRecommendationsDataSequence.map((key) => {
                        const possibleAnswers = get(
                            surveyInstanceRecommendationsData,
                            `${[key]}.possibleAnswers`,
                            []
                        );
                        const answers = get(
                            surveyInstanceRecommendationsData,
                            `${[key]}.answers`,
                            []
                        );
                        const question = get(
                            surveyInstanceRecommendationsData,
                            `${[key]}.question`,
                            ""
                        );

                        return (
                            <Collapse.Item title={question} key={key} name={key}>
                                <ul className={styles["checkbox-list"]}>
                                    {possibleAnswers.map((item, i) => {
                                        const isChecked = Boolean(
                                            answers.find(({ id }) => id === item.id) ||
                                                recommendations.find(
                                                    ({ id, answerIds }) =>
                                                        id === key &&
                                                        answerIds.includes(item.id)
                                                )
                                        );

                                        return (
                                            <li className={styles.listItem} key={item.id}>
                                                {item.answer && (
                                                    <>{item.answer}&nbsp; &nbsp;</>
                                                )}
                                                {/* <checkbox /> */}
                                                <Checkbox
                                                    key={i}
                                                    className={styles.checkboxItem}
                                                    label={``}
                                                    checked={isChecked}
                                                    disabled={isSurveyStatusCompleted}
                                                    onChange={(isCheckedValue) => {
                                                        setRecommendations(
                                                            recommendations.map(
                                                                (recommendation) => {
                                                                    if (
                                                                        recommendation.id ===
                                                                        key
                                                                    ) {
                                                                        return {
                                                                            ...recommendation,
                                                                            answerIds:
                                                                                isCheckedValue
                                                                                    ? [
                                                                                          ...recommendation.answerIds,
                                                                                          item.id,
                                                                                      ]
                                                                                    : recommendation.answerIds.filter(
                                                                                          (
                                                                                              el
                                                                                          ) =>
                                                                                              el !==
                                                                                              item.id
                                                                                      ),
                                                                        };
                                                                    }

                                                                    return recommendation;
                                                                }
                                                            )
                                                        );
                                                        setTouchedIds([...touchedIds, key]);

                                                        if (isCheckedValue) {
                                                            addResourceLink(item.id);
                                                        } else {
                                                            removeResourceLink(item.id);
                                                        }
                                                    }}
                                                />
                                                {item.resource && (
                                                    <>
                                                        &nbsp; &nbsp;
                                                        <ResourceLink
                                                            url={item.resource.url}
                                                            name={item.resource.name}
                                                        />
                                                    </>
                                                )}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </Collapse.Item>
                        );
                    })}
                </Collapse>
            </div>
        );
    };

    return (
        <Fragment>
            {accountType === USER_ROLES.patient
                ? renderRecommendationsList()
                : renderRecommendationsListCollapsed()}
        </Fragment>
    );
}

export default Recommendations;
